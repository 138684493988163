import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { css } from '@emotion/react';

const headingStyles = css`
  cursor: pointer;
  text-decoration: ${({ expanded }) => (expanded ? 'underline' : 'none')};
  color: ${({ expanded }) => (expanded ? 'blue' : 'inherit')};
`;
const SubscriptionInfoTableN = React.memo(({ editableSubscriptions, handleSubscriptionChange, saveSubscriptionChanges }) => {
// const SubscriptionInfoTableN = ({ editableSubscriptions, handleSubscriptionChange, saveSubscriptionChanges }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        My Subscriptions
      </Typography>
      {editableSubscriptions.map((subscription, index) => (
        <div key={subscription.subscription_id} style={{ marginBottom: '20px' }}>
          <Typography
            variant="h6"
            css={headingStyles}
            expanded={expandedIndex === index}
            onClick={() => handleToggle(index)}
          >
            {subscription.domain_name} {expandedIndex === index ? '▼' : '▶'}
          </Typography>
          {expandedIndex === index && (
            <div>
              <TextField
                label="Subscription ID"
                value={subscription.subscription_id}
                disabled
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <TextField
                label="Name"
                type="text"
                value={subscription.package_id}
                disabled
               /* onChange={(e) => handleSubscriptionChange(index, 'package_id', e.target.value)}*/
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <TextField
                label="Start Date"
                value={subscription.start_date}
                disabled
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <TextField
                label="End Date"
                value={subscription.end_date}
                disabled
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <TextField
                label="Associated Email"
                type="email"
                value={subscription.associated_email}
               /* disabled */
                onChange={(e) => handleSubscriptionChange(index, 'associated_email', e.target.value)}
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <TextField
                label="Description"
                multiline
                value={subscription.description}
                onChange={(e) => handleSubscriptionChange(index, 'description', e.target.value)}
                fullWidth
                rows={3}
                style={{ marginBottom: '10px' }}
              />
              <TextField
                label="Folder"
                value={subscription.folder_link}
                disabled
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <TextField
                label="Domain Name"
                value={subscription.domain_name}
                disabled
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <Button onClick={() => saveSubscriptionChanges(index)} variant="contained" color="primary">
                Save
              </Button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
//};
});
export default SubscriptionInfoTableN;
