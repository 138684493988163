// App.js
import 'core-js/stable';
import 'regenerator-runtime/runtime'; // Only needed if using async/await
import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, AppBar, Toolbar, IconButton, Button, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
//import RegisterForm from './components/RegisterForm';
import RegisterFormN from './components/RegisterFormN';
import OtpForm from './components/OtpForm';
import OtpFormN from './components/OtpFormN';
//import UserInfo from './components/UserInfo';
import UserInfoN from './components/UserInfoN';
//import LeadsTable from './components/LeadsTable'; // Import LeadsTable component
import LeadsTableN from './components/LeadsTableN'; // Import LeadsTable component
//import ProcessSubscriptionsTable from './components/ProcessSubscriptionsTable'; // Import LeadsTable component
import ProcessSubscriptionsTableN from './components/ProcessSubscriptionsTableN'; // Import LeadsTable component
import { API_BASE_URL } from './components/constants';

import './styles.css'; // Import the CSS file for styling
//import React, { useState, useEffect } from 'react';
const App = () => {
  const [step, setStep] = useState('login');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  
  useEffect(() => {
    if (token) {
      fetchUserInfo();
    }
  }, [token]);

  const fetchUserInfo = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/account_info`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user information');
      }
      const data = await response.json();
      setUserInfo({ ...data }); // Create a new object with updated data
      setStep('userInfo');
      setMessage("");
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  const handleRegister = async (formdata) => {
    try {
      const response = await fetch(`${API_BASE_URL}/create_account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( formdata )
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Registration failed');
      }
      setMessage(data.message);
      if (data.message.includes('One-time code')) {
        setStep('otp');
      }
    } catch (error) {
      console.error('Error:', error.message);
      // Display an error alert
     
    // Display modified error message using alert
    alert('An error occurred: ' + (error.message || 'Registration failed'));
    }
  };

  const handleLogin = async (formemail) => {
    try {
      const response = await fetch(`${API_BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( formemail )
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }
      setMessage(data.message);
      if (data.message.includes('One-time code')) {
        setStep('otp');
      }
    } catch (error) {
      console.error('Error:', error.message);
      // Display an error alert
      alert('An error occurred: ' + (error.message || 'Login failed'));
    }
  };

  const handleValidateLogin = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/validate_login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, otp })
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'OTP validation failed');
      }
      setMessage(data.message);
      if (data.success) {
        setToken(data.access_token);
       
      }
    } catch (error) {
      console.error('Error:', error.message);
      // Display an error alert
      alert('An error occurred: ' + (error.message || 'OTP Validation failed'));
    }
  };

  const handleUpdateSubscription = async (subscriptionId, updatedSubscriptionData) => {
    try {
        // Include subscription_id in the updated subscription data
      const updatedDataWithId = {
        subscription_id: subscriptionId,
        ...updatedSubscriptionData
      };
      console.log(updatedDataWithId)
      const response = await fetch(`${API_BASE_URL}/update_subscription`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(updatedDataWithId)
      });
      if (!response.ok) {
        throw new Error('Failed to update subscription information');
      }
      setMessage('Subscription information updated successfully');
      fetchUserInfo();
      alert('Subscription information updated successfully');
    } catch (error) {
      console.error('Error:', error.message);
      setMessage(error.message||'Subscription information not updated')
      alert('An error occurred: ' + (error.message || 'Subscription information not updated'));
    }
  };

  const handleUpdateUserInfo = async (updatedFields) => {
    try {
      const response = await fetch(`${API_BASE_URL}/update_user`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(updatedFields)
      });
      if (!response.ok) {
        throw new Error('Failed to update user information');
      }
      setMessage('User information updated successfully');
      alert('User information updated successfully');
      fetchUserInfo();
    } catch (error) {
      console.error('Error:', error.message);
      setMessage('User information not updated')
      alert('An error occurred: ' + (error.message || 'User information not updated'));

    }
  };

const handleCreateSubscription = async (newSubscription) => {
    try {
      const response = await fetch(`${API_BASE_URL}/create_subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(newSubscription)
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }
      setMessage('New subscription created successfully');
      alert('New subscription created successfully');
      fetchUserInfo();
      console.log(data); // Handle response from create_subscription endpoint
    } catch (error) {
      console.error('Error:', error);
      setMessage('New subscription not created')
      alert('An error occurred: ' + (error.message || 'New subscription not created'));
    }
  };
  
  
  let content;
  switch (step) {
    case 'register':
    case 'login':
      content = (
        <Container maxWidth="sm">
          <RegisterFormN
            email={email}
            setEmail={setEmail}
            handleRegister={handleRegister}
            handleLogin={handleLogin}
            
          />
        </Container>
      );
      break;
    case 'otp':
      content = (
        <Container maxWidth="sm">
          <OtpFormN otp={otp} setOtp={setOtp} handleLogin={handleValidateLogin} />
        </Container>
      );
      break;
    case 'userInfo':
      content = (
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LeadsTableN token={token} setMessage={setMessage} />
            </Grid>
            <Grid item xs={12}>
              <ProcessSubscriptionsTableN token={token} setMessage={setMessage} />
            </Grid>
            <Grid item xs={12}>
              <UserInfoN
                userInfo={userInfo}
                handleUpdateSubscription={handleUpdateSubscription}
                handleUpdateUser={handleUpdateUserInfo}
                handleCreateSubscription={handleCreateSubscription}
                token={token}
                setMessage={setMessage}
              />
            </Grid>
          </Grid>
        </Container>
      );
      break;
    default:
      content = (
        <Container maxWidth="sm">
          <RegisterFormN
            email={email}
            setEmail={setEmail}
            handleRegister={handleRegister}
            handleLogin={handleLogin}
          />
        </Container>
      );
  }

  return (
    <div className="app-container" style={{ backgroundColor: '#f0f0f0', minHeight: '100vh' }}>
      <AppBar position="static" style={{ backgroundColor: '#2196f3' }}>
       <Toolbar>
        {/*  <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
           
            <MenuIcon/>
            
          </IconButton> */}
                    
        </Toolbar>
      </AppBar> 
      <main>
        <Container maxWidth="lg" style={{ marginTop: '20px', marginBottom: '20px' }}>
          {content}
          {message && <p>{message}</p>}
        </Container>
      </main>
     {/*<footer style={{ backgroundColor: '#333', color: '#fff', padding: '20px 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                About Us
              </Typography>
              <Typography variant="body2" gutterBottom>
              At GENmAI, we are driven by a singular mission: to empower individuals and businesses to unlock their full potential through the power of artificial intelligence (AI). Founded on the principles of innovation, creativity, and excellence, we strive to revolutionize industries and shape the future of technology.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Contact Us
              </Typography>
              <Typography variant="body2" gutterBottom>
                Email: info@genmai.ai
              </Typography>
              
            </Grid>
          </Grid>
        </Container>
    </footer> */}
    </div>
  );


};

export default App;
