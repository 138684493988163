import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const OtpFormN = ({ otp, setOtp, handleLogin }) => {
  const inputRef = useRef(null);

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <form
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      onSubmit={handleSubmit}
    >
      <TextField
        label="OTP"
        type="text"
        variant="outlined"
        value={otp}
        onChange={handleChange}
        inputRef={inputRef}
        autoFocus
        sx={{ marginBottom: '1rem', width: '300px' }} // Adjust styling here
      />
      <Button type="submit" variant="contained" color="primary">
        Submit OTP
      </Button>
    </form>
  );
};

export default OtpFormN;
