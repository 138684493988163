import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { API_BASE_URL } from './constants';

const tableHeadingStyles = css`
  cursor: pointer;
  &.open {
    transform: rotate(180deg);
  }
`;

const LeadsTableN = ({ token, setMessage }) => {
  const [leads, setLeads] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleTable = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const emailResponse = await fetch(`${API_BASE_URL}/process_emails`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!emailResponse.ok) {
          throw new Error('Failed to fetch emails: Not authorized');
        }
        const emailData = await emailResponse.json();
        console.log('Emails fetched:', emailData.message);

        // After emails are fetched, call the API to fetch leads
        fetchLeads();
      } catch (error) {
        console.error('Error fetching emails:', error);
        setErrorMessage('Failed to fetch emails. Please try again later.');
      }
    };

    // Call the function to fetch emails
    fetchEmails();
  }, []);

  const transformData = (data) => {
    // Example transformation: Extracting an array from an object
    if (Array.isArray(data)) {
      // Data is already in the expected format (array)
      return data;
    } else if (typeof data === 'object' && data.hasOwnProperty('leads')) {
      // Extract the 'leads' property from the object
      return data.leads;
    } else {
      // Handle unexpected data format
      console.warn('Unexpected data format:', data);
      return [];
    }
  };

  const fetchLeads = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/leads`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch leads');
      }
      const data = await response.json();
      // Perform data transformation if necessary
      const transformedData = transformData(data);

      setLeads(transformedData);
      
    } catch (error) {
      console.error('Error fetching leads:', error);
      setErrorMessage('Failed to fetch leads. Please try again later.');
    }
  };

  const handleLeadChange = (e, index, field) => {
    const updatedLeads = [...leads];
    updatedLeads[index][field] = e.target.textContent;
    setLeads(updatedLeads);
  };

  // Assume you have a function to update a lead object
  const updateLead = (index, fieldName, value) => {
    setLeads(prevLeads => {
      const updatedLeads = [...prevLeads];
      updatedLeads[index][fieldName] = value;
      return updatedLeads;
    });
  };
  
  // Function to handle saving lead data
  const handleSaveLead = async (lead) => {
    try {
      const response = await fetch(`${API_BASE_URL}/update_lead`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(lead)
      });
      if (!response.ok) {
        throw new Error('Failed to save lead data');
      }
      setMessage('Lead data saved successfully');
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  
  // Function to handle converting lead to account
  const handleConvertToAccount = async (lead) => {
    try {
      const response = await fetch(`${API_BASE_URL}/create_lead_account_subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(lead)
      });
      if (!response.ok) {
        throw new Error('Failed to convert lead to account');
      }
      setMessage('Lead converted to account successfully');
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  
  return (
        <div>
            {leads.length > 0 && (
            <Typography
            css={tableHeadingStyles}
            className={`${isOpen ? 'open' : 'closed'}`}
            onClick={toggleTable}
            variant="h5"
          >
            Leads Table {isOpen ? '▼' : '►'}
          </Typography>
        )}
        {isOpen && leads.length > 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Actions</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Select Product</TableCell>
                  <TableCell>Website Address</TableCell>
                  <TableCell>Business Description</TableCell>
                  <TableCell>Additional Description</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>ID</TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
                {leads.map((lead, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Button
                        onClick={() => handleSaveLead(lead)}
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => handleConvertToAccount(lead)}
                        variant="contained"
                        color="secondary"
                      >
                        Convert
                      </Button>
                    </TableCell>
                    <TableCell
                      contentEditable
                      onBlur={(e) => updateLead(index, 'name', e.target.textContent)}
                    >
                      {lead.name}
                    </TableCell>
                    <TableCell
                      contentEditable
                      onBlur={(e) => updateLead(index, 'email', e.target.textContent)}
                      type="email"
                      inputMode="email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    >
                      {lead.email}
                    </TableCell>
                    <TableCell>
                      <select
                        value={lead.select_product}
                        onChange={(e) => updateLead(index, 'select_product', e.target.value)}
                      >
                        <option value="Genmai Content">Genmai Content</option>
                        <option value="Genmai Web">Genmai Web</option>
                        <option value="Genmai Content Basic">Genmai Content Basic</option>
                        <option value="Genmai Content Plus">Genmai Content Plus</option>
                        <option value="Genmai Content Pro">Genmai Content Pro</option>
                        <option value="Genmai Web Basic">Genmai Web Basic</option>
                        <option value="Genmai Web Plus">Genmai Web Plus</option>
                        <option value="Genmai Web Pro">Genmai Web Pro</option>
                      </select>
                    </TableCell>
                    <TableCell
                      contentEditable
                      onBlur={(e) => updateLead(index, 'website_address', e.target.textContent)}
                      type="url"
                    >
                      {lead.website_address}
                    </TableCell>
                    <TableCell
                      contentEditable
                      onBlur={(e) => updateLead(index, 'business_description', e.target.textContent)}
                    >
                      {lead.business_description}
                    </TableCell>
                    <TableCell
                      contentEditable
                      onBlur={(e) => updateLead(index, 'additional_description', e.target.textContent)}
                    >
                      {lead.additional_description}
                    </TableCell>
                    <TableCell
                      contentEditable
                      onBlur={(e) => updateLead(index, 'date', e.target.textContent)}
                      type="date"
                    >
                      {lead.date}
                    </TableCell>
                    <TableCell
                      contentEditable
                      onBlur={(e) => updateLead(index, 'status', e.target.textContent)}
                    >
                      {lead.status}
                    </TableCell>
                    <TableCell>{lead.id}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
 /* return (
    <div>
      {leads.length > 0 && (
        <Typography
          css={tableHeadingStyles}
          className={`${isOpen ? 'open' : 'closed'}`}
          onClick={toggleTable}
          variant="h5"
        >
          Leads Table {isOpen ? '▼' : '►'}
        </Typography>
      )}
      {isOpen && leads.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Select Product</TableCell>
                <TableCell>Website Address</TableCell>
                <TableCell>Business Description</TableCell>
                <TableCell>Additional Description</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leads.map((lead, index) => (
                <TableRow key={index}>
                  <TableCell>{lead.id}</TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'name', e.target.textContent)}
                  >
                    {lead.name}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'email', e.target.textContent)}
                  >
                    {lead.email}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'select_product', e.target.textContent)}
                  >
                    {lead.select_product}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'website_address', e.target.textContent)}
                  >
                    {lead.website_address}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'business_description', e.target.textContent)}
                  >
                    {lead.business_description}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'additional_description', e.target.textContent)}
                  >
                    {lead.additional_description}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'date', e.target.textContent)}
                  >
                    {lead.date}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'status', e.target.textContent)}
                  >
                    {lead.status}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleSaveLead(lead)}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => handleConvertToAccount(lead)}
                      variant="contained"
                      color="secondary"
                    >
                      Convert
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );*/
};

export default LeadsTableN;
