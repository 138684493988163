import React from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { css } from '@emotion/react';

const headingStyles = css`
  cursor: pointer;
  text-decoration: ${({ expanded }) => (expanded ? 'underline' : 'none')};
  color: ${({ expanded }) => (expanded ? 'blue' : 'inherit')};
`;

const AccountInfoTableN = ({ userInfo, editableUser, handleUserChange, saveUserChanges, expanded, onToggle }) => {
  return (
    <div>
      <Typography
        variant="h5"
        css={headingStyles}
        expanded={expanded}
        onClick={onToggle}
      >
        Account Information
        <span style={{ marginLeft: '5px', fontSize: '0.8em' }}>{expanded ? '▼' : '▶'}</span>
      </Typography>
      {expanded && (
        <div>
          <Typography>
            <strong>Account ID:</strong> {userInfo.accountid}
          </Typography>
          <TextField
            name="name"
            label="Name"
            value={editableUser.name}
            onChange={handleUserChange}
            variant="outlined"
            style={{ width: '98%', minHeight: '20px' }}
            margin="normal"
          />
          <TextField
            name="email"
            label="Email"
            type="email"
            value={editableUser.email}
            onChange={handleUserChange}
            variant="outlined"
            style={{ width: '98%', minHeight: '20px' }}
            margin="normal"
          />
          <TextField
            name="address"
            label="Address"
            value={editableUser.address}
            onChange={handleUserChange}
            variant="outlined"
            style={{ width: '98%', minHeight: '20px' }}
            margin="normal"
          />
          <TextField
            name="phone"
            label="Phone"
            value={editableUser.phone}
            onChange={handleUserChange}
            variant="outlined"
            style={{ width: '98%', minHeight: '20px' }}
            margin="normal"
          />
          <Button onClick={saveUserChanges} variant="contained" color="primary">
            Save User Info
          </Button>
        </div>
      )}
    </div>
  );
};

export default AccountInfoTableN;
