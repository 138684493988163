import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { API_BASE_URL } from './constants';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

const tableHeadingStyles = css`
  cursor: pointer;
  &.open {
    transform: rotate(180deg);
  }
`;

const ProcessSubscriptionsTableN = ({ token, setMessage }) => {
  const [leads, setLeads] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const toggleTable = () => {
    setIsOpen(!isOpen);
  };

  const transformData = (data) => {
    if (Array.isArray(data)) {
      return data;
    } else if (typeof data === 'object' && data.hasOwnProperty('leads')) {
      return data.leads;
    } else {
      console.warn('Unexpected data format:', data);
      return [];
    }
  };

  useEffect(() => {
    const fetchProcessSubscriptions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/get_subscriptions_genmai_content`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch subscriptions');
        }
        const data = await response.json();
        const transformedData = transformData(data);
        setLeads(transformedData);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        setErrorMessage('Failed to fetch subscriptions. Please try again later.');
      }
    };
    fetchProcessSubscriptions();
  }, []);

  const updateLead = (index, fieldName, value) => {
    setLeads(prevLeads => {
      const updatedLeads = [...prevLeads];
      updatedLeads[index][fieldName] = value;
      return updatedLeads;
    });
  };

  const handleSaveProcessSubscription = async (lead) => {
    try {
      const response = await fetch(`${API_BASE_URL}/update_subscription_genmai_content`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(lead)
      });
      if (!response.ok) {
        throw new Error('Failed to save lead data');
      }
      setMessage('Lead data saved successfully');
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <div>
      {leads.length > 0 && (
        <Typography
          css={tableHeadingStyles}
          className={`table-heading ${isOpen ? 'open' : 'closed'}`}
          onClick={toggleTable}
          variant="h5"
        >
          Subscriptions Table {isOpen ? '▼' : '►'}
        </Typography>
      )}
      {isOpen && leads.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '10%' }}>ID</TableCell>
                  <TableCell style={{ width: '20%' }}>Website</TableCell>
                  <TableCell style={{ width: '10%' }}>Email</TableCell>
                  <TableCell style={{ width: '10%' }}>Package</TableCell>
                  <TableCell style={{ width: '20%' }}>Description</TableCell>
                  <TableCell style={{ width: '10%' }}>Start Date</TableCell>
                  <TableCell style={{ width: '10%' }}>End Date</TableCell>
                  <TableCell style={{ width: '10%' }}>Last Service Date</TableCell>
                  <TableCell style={{ width: '10%' }}>Status</TableCell>
                  <TableCell style={{ width: '10%' }}>Folder Link</TableCell>
                  <TableCell style={{ width: '10%' }}>Account ID</TableCell>
                  <TableCell style={{ width: '10%' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
            <TableBody>
              {leads.map((lead, index) => (
                <TableRow key={index}>
                  <TableCell>{lead.subscription_id}</TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'domain_name', e.target.textContent)}
                    style={{ width: '100%' }}
                  >
                    {lead.domain_name}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'associated_email', e.target.textContent)}
                    style={{ width: '100%' }}
                  >
                    {lead.associated_email}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'package_id', e.target.textContent)}
                    style={{ width: '100%' }}
                  >
                    {lead.package_id}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'description', e.target.textContent)}
                    style={{ width: '100%' }}
                  >
                    {lead.description}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'start_date', e.target.textContent)}
                    style={{ width: '100%' }}
                  >
                    {lead.start_date}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'end_date', e.target.textContent)}
                    style={{ width: '100%' }}
                  >
                    {lead.end_date}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'last_serviced_date', e.target.textContent)}
                    style={{ width: '100%' }}
                  >
                    {lead.last_serviced_date}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'status', e.target.textContent)}
                    style={{ width: '100%' }}
                  >
                    {lead.status}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'folder_link', e.target.textContent)}
                    style={{ width: '100%' }}
                  >
                    {lead.folder_link}
                  </TableCell>
                  <TableCell
                    contentEditable
                    onBlur={(e) => updateLead(index, 'accountid', e.target.textContent)}
                    style={{ width: '10%', minWidth: '100px', wordWrap: 'break-word' }}
                  >
                    {lead.accountid}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => handleSaveProcessSubscription(lead)} variant="contained" color="primary">
                      Save
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ProcessSubscriptionsTableN;
